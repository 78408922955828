// src/components/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserForm from './UserForm';

const AdminDashboard = () => {
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('/users?role=manager', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setManagers(response.data))
      .catch(error => console.error(error));
  }, []);

  const addManager = (manager) => {
    setManagers([...managers, manager]);
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <UserForm role="manager" onAddUser={addManager} />
      <ul>
        {managers.map(manager => (
          <li key={manager.id}>{manager.email}</li>
        ))}
      </ul>
    </div>
  );
};

export default AdminDashboard;
