// src/components/InventoryDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const InventoryDashboard = () => {
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    axios.get('/inventory')
      .then(response => setInventory(response.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <h1>Inventory Dashboard</h1>
      <ul>
        {inventory.map(item => (
          <li key={item.id}>{item.productName} - {item.quantity}</li>
        ))}
      </ul>
    </div>
  );
};

export default InventoryDashboard;
