// src/components/UploadInventory.js
import React, { useState } from 'react';
import axios from 'axios';

const UploadInventory = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    axios.post('/inventory/upload', formData)
      .then(response => {
        setFile(null);
        // handle success
      })
      .catch(error => console.error(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} required />
      <button type="submit">Upload Inventory</button>
    </form>
  );
};

export default UploadInventory;
