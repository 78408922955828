// src/components/SuperAdminDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserForm from './UserForm';

const SuperAdminDashboard = () => {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('/users?role=admin', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setAdmins(response.data))
      .catch(error => console.error(error));
  }, []);

  const addAdmin = (admin) => {
    setAdmins([...admins, admin]);
  };

  return (
    <div>
      <h1>Super Admin Dashboard</h1>
      <UserForm role="admin" onAddUser={addAdmin} />
      <ul>
        {admins.map(admin => (
          <li key={admin.id}>{admin.email}</li>
        ))}
      </ul>
    </div>
  );
};

export default SuperAdminDashboard;


