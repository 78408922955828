import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SuperAdminDashboard from './components/SuperAdminDashboard';
import AdminDashboard from './components/AdminDashboard';
import InventoryDashboard from './components/InventoryDashboard';
import AddInventoryForm from './components/AddInventoryForm';
import UploadInventory from './components/UploadInventory';
import ScanInventoryOut from './components/ScanInventoryOut';
import OrderForm from './components/OrderForm';
import Login from './components/Login';
import Register from './components/Register';
import NavBar from './components/NavBar';

const PrivateRoute = ({ element: Element, ...rest }) => (
  <Route
    {...rest}
    element={localStorage.getItem('token') ? <Element /> : <Navigate to="/login" />}
  />
);

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <PrivateRoute path="/superadmin" element={<SuperAdminDashboard />} />
        <PrivateRoute path="/admin" element={<AdminDashboard />} />
        <PrivateRoute path="/inventory" element={<InventoryDashboard />} />
        <PrivateRoute path="/add-inventory" element={<AddInventoryForm />} />
        <PrivateRoute path="/upload-inventory" element={<UploadInventory />} />
        <PrivateRoute path="/scan-inventory-out" element={<ScanInventoryOut />} />
        <PrivateRoute path="/order" element={<OrderForm />} />
      </Routes>
    </Router>
  );
}

export default App;
