// src/components/ScanInventoryOut.js
import React, { useState } from 'react';
import axios from 'axios';

const ScanInventoryOut = () => {
  const [barcode, setBarcode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/inventory/scan-out', { barcode })
      .then(response => {
        setBarcode('');
        // handle success
      })
      .catch(error => console.error(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={barcode}
        onChange={(e) => setBarcode(e.target.value)}
        placeholder="Barcode"
        required
      />
      <button type="submit">Scan Out Inventory</button>
    </form>
  );
};

export default ScanInventoryOut;
